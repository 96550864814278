<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
      

        <banner
          title="Assigned Services"
          :breadcrumb="[
             {
              label: 'Dashboard',
            },
           
            {
              label: 'Assigned Services',
            }

          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
            
            >
              <div class="card-body">


                <div class="row">
                  <div class="col text font">

                    <h4><b style="color: #00364f;">Assigned Services</b></h4>
                  </div>
                  <!-- <div class="col text-end cus-p-1">
                   
                <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>
                          &nbsp;

                  </div> -->
                    <!-- <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" >

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div> -->
                </div>



                <div class="row ">
                  <div class="d-md-none d-xl-none d-block col-3 text font cus-p-1">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                    <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>
               
                </div>
                <!-- start list area  -->




                <!-- start list area  -->
                <div class="row">
                 
                 

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
           
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                          <th>Code</th>
                          <th>Service</th>
                          <th>Asign Services</th>
                          <th class="text-center">Status</th>
                        </tr>

                        <tbody class="text">
                          <tr v-for="(service, index) in services" :key="index">
                            <td>
                              <span v-if="service.logo">
                                <img
                                  style="width: 30px; height: 30px"
                                  :src="
                                    this.$store.state.imgUrl +
                                    `/service-icons/${service.id}/thumbs/${service.logo}`
                                  "
                                  alt=""
                                />
                              </span>
                              <span v-else>
                                <img
                                  style="width: 30px; height: 30px"
                                  :src="this.$store.state.placeholderImg"
                                  alt=""
                                />
                              </span>
                            </td>
                            <td class="text-truncate">{{ service.code }}</td>

                            <td class="text-truncate">{{ service.name }}</td>
                          
                            <td class="text-truncate">
                              <p
                                v-if="service.prof_ServiceStatus"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: #fa00b3;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 70px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ service.prof_ServiceStatus }}
                              </p>
                            </td>
                            <td class="text-end">
                              <p
                                v-if="service.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 70px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                Active
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 70px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                InActive
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
                    
               <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadService(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadService(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadService(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadService(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadService(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadService(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadService(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadService(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadService(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                    <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                     <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadService(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadService(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadService(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadService(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadService(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

          
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>





   
  <!-- Right Sidebar starts -->
    <div class="modal  modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
              <li  @click="valueNullFilter()" class="list-group-item"><div class="form-check">
  <input v-model="filterType" value="code" class="form-check-input" type="radio" name="flexRadioDefault" id="code">
  <label class="form-check-label" for="code">
  Code
  </label>
</div></li>

  <li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="name" class="form-check-input" type="radio" name="flexRadioDefault" id="name">
  <label class="form-check-label" for="name">
    Service Name
  </label>
</div></li>



<li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 <input type="text" v-if="filterType != 'all'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 






</div>

<button  @click="loadService()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends --> 
    
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- end  modal -->
</template>

//


<script>
import Spinner from "../../../components/professional/comman/Spinner.vue";
import Banner from "../../../components/professional/comman/Banner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
var _ = require("lodash");
export default {
  name: "staffservices",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      filterValue:'',
      filterType:'',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      loading: false,
      services: [],
      staffservices: [],
    };
  },
  methods: {
    valueNullFilter() {
      this.filterValue = "";
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadService();
    },
    loadService(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
     

     if( this.filterType == 'all'){
         this.filterValue = ''
         this.filterType = ''
       }


       if(this.filterValue != null && this.filterType != 'all'){
      
         if(this.filterType != 'all'){

           pageUrl += `staff/service?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
     }else{
         pageUrl += `staff/service?per_page=${this.per_page}`;
        }

     
       }
   } else {
     pageUrl += `&per_page=${this.pagination.per_page}`;
   }
   if (this.table_search) {
     pageUrl += `&searchTerm=${this.table_search}`;
   }
   if (page != null) {
     pageUrl += `&page=${page}`;
   }
      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.services = res.data.data.data;
          this.pagination = res.data.data;
          console.log('=========================================================')
          // console.log(this.pagination)
          this.filterValue= ""
          this.loadstaffservice();
        })
        .finally(() => (this.loading = false));
    },
    loadstaffservice() {
      this.loading = true;
      this.$axios
        .get("staff/staffservice?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data.data)
          this.staffservices = res.data.data;

          var challansData = this.staffservices;
          var projects = this.services;
          _.forEach(challansData, function (value) {
            if (
              _.find(projects, function (o) {
                return o.id == value.service_id;
              })
            ) {
              let index = _.findIndex(
                projects,
                (e) => {
                  return e.id == value.service_id;
                },
                0
              );

              projects[index].prof_ServiceStatus = "Asigned";
              projects[index].prof_FeesAmt = value.professionalFee;
            }
          });
          this.services = projects;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
        document.title =this.$store.state.professionalPageTitles.service
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadService();
      // this.loadProfessionalWallet()
      this.loadstaffservice();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}


@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}



input:checked {
  /* background-color: #7367f0; */
  border-color: #ff000c;
  border-width: 5px;
}
.form-check-input:checked {
  background-color: #fdfdfd;
  /* border-color: #7367f0; */
}


.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 10px 10px 0px 10px;
}
</style>
